export const environment = {
  node_static_url: "https://mxf.laoevisa.gov.la/api",
  node_image_url: "https://mxf.laoevisa.gov.la",
  article_image_static_url: "https://mxf.laoevisa.gov.la/thumbnail/",
  node_payment_url: "https://mxf.laoevisa.gov.la/payment-api",
  sentry_dsn: "https://c6f9ff159b1649f6ae2ca84885816cf8@sentry.laogw.la/2",
  production: true,
  defaultLang: "en",
  selectedLang: "selectedLang",
  localAuthenInfo: "localAuthenInfo",
  dealerAuthenInfo: "dealerAuthenInfo",
  agentAuthenInfo: "agentAuthenInfo",
  version: "1.2.10",
  featureNIB3: false,
  featureSSID: false,
  browserTrack: false,
  maintainerWeb: false,
}
