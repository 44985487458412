import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { MatDialog, MatRadioChange } from "@angular/material"
import { Router } from "@angular/router"
import {
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap"
import { LOCAL_STORAGE, WINDOW } from "@ng-toolkit/universal"
import * as $ from "jquery"
import * as moment from "moment"
import { NgxSpinnerService } from "ngx-spinner"
import { AppComponent } from "src/app/app.component"
import { CustomerImage, Person } from "src/app/models/formData"
import { FormDataService } from "src/app/services/form-data.service"
import { RestService } from "src/app/services/rest.service"
import { ServiceSEOService } from "src/app/services/service-seo.service"
import { environment } from "src/environments/environment"
import { NgbDateCustomParserFormatter } from "../../functions/ngb-date-fr-parser-formatter"
import { ImageCropperComponent } from "../image-cropper/image-cropper.component"
import { ImageCroppedEvent } from "../image-cropper/interfaces"
import { PopUpComponent } from "../pop-up/pop-up.component"
// import { NgxImageCompressService } from "ngx-image-compress-legacy";
import { NgxImageCompressService } from "ngx-image-compress";
import { ActivatedRoute } from "@angular/router";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
@Component({
  selector: "app-application",
  templateUrl: "./application.component.html",
  styleUrls: ["./application.component.css"],
  // providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class ApplicationComponent implements OnInit, AfterViewInit {
  NIB3 = environment.featureNIB3;
  mDataArrayCountry: any
  mDataArrayCountryBirth: any
  mDataArrayVisaType: any[]
  mDataArrayPortEntry: any[]
  mDataArrayCallingCode: any[]
  mPurpose: any[]
  filteredPortOfEntry: any[]
  initialLoadDob = true;
  initialLoadIssue = true;
  initialLoadExpire = true;

  passportSrc: string
  person: Person
  custImage: CustomerImage

  facePhoto: number;
  facePassport: number;
  facePhotoMessage: string = "";
  facePassMessage: string = "";
  form: any;
  family_side_touch = false;
  family_member_names_touch = false;
  family_relation_touch = false;

  imageChangedEvent: any = "";
  imageChangedEventPass: any = "";
  imageChangedEventPassCover: any = "";
  croppedImage: any = "";
  croppedImagePass: any = "";
  croppedImagePassCover: any = "";
  cropperReady = false;
  cropperReadyPass = false;
  countryBirthSelected: Number

  issueDate: { year: number; month: number; day: number }
  expiredDate: { year: number; month: number; day: number }
  intendedDate: { year: number; month: number; day: number }

  myExpiredDate: { year: number; month: number; day: number }

  // Validate intended
  intentedMessage: string
  intentedMessage_cn: string
  intentedMessage_fr: string
  isIntendedError = false;
  isPhotoBigSize = false;
  isPassportBigSize = false;
  isCoverPassportBigSize = false;

  acceptTerm = false;
  isExpiredDate = false;
  selectedPhoneCode = "USA +1";
  selectedCountry
  @Input() formDatas: FormData
  isRequestCountry = false;
  selectedFile: File
  selectedFileFlight: File
  selectedFileThirdVisa: File
  selectedFileGuarantor: File
  hotel_doc_big_size = false;
  flight_doc_big_size = false;
  third_visa_doc_big_size = false;
  guarantor_visa_doc_big_size = false;

  hotel_warning_message = "";
  flight_warning_message = "";
  third_visa_warning_message = "";
  guarantor_warning_message = "";
  personForm: FormGroup
  submitted = false;

  photo_count = 0;
  passport_count = 0;
  isPhotoSmallSize = false;
  isPassportSmallSize = false;
  isPassportCoverSmallSize = false;

  @ViewChild("content", { static: true }) private content
  days = 0;
  except_message = "";
  bank_statement_doc_big_size: boolean
  selectedFileBankStatement: any
  next_2month = { year: 0, month: 0, day: 0 };
  minDate = { year: 0, month: 0, day: 0 };
  today = { year: 0, month: 0, day: 0 };
  @ViewChild(ImageCropperComponent, { static: true })
  imageCropper: ImageCropperComponent
  @ViewChild("imagePhoto", { static: true })
  photoCropper: ImageCropperComponent
  @ViewChild("imagePassport", { static: true })
  passportCropper: ImageCropperComponent
  isPassportCoverBigSize: boolean
  passport_cover_count: number
  cropperReadyPassCover: boolean
  citizens = "";
  selectedLang: string

  isOrdinaryPassport = true;

  familySide: string = "";
  proofFile: any
  proofFileName: string = "";
  proofFilePreview: any
  proofFileCount: number = 0;
  personRace: number = 0;

  popupCheckData = [];
  imgPass: any
  mrzPass: any
  mrzData: any
  expDate: any[] = [];
  dateBirth: any[] = [];
  timerInterval: any
  sessionId: any
  data = {
    session_id: "",
    input_id: "",
    input_value: "",
  };
  imgResultBeforeCompression: string = "";
  imgResultAfterCompression: string = "";
  setFormLocalStorage = {
    photoBase64: "",
    passportBase64: "",
    photoImg: "",
    passportImg: "",
    photoImgUrl: "",
    passportImgUrl: "",
    lastName: "",
    firstName: "",
    gender: "",
    dateBirth: { year: 0, month: 0, day: 0 },
    genderSelect: "",
    countryBirth: null,
    telephone: "",
    occupation: "",
    residentialAddress: "",
    stateProvince: "",
    countryCity: null,
    passportNum: "",
    dateIssue: { year: 0, month: 0, day: 0 },
    dateExpire: { year: 0, month: 0, day: 0 },
    countryPassport: null,
    intendDate: "",
    vehicle: "",
    purposeTrip: null,
    addressDuringVisit: "",
    nextDestination: "",
    checkExpDate: dayjs().format("YYYY-MM-DD"),
  };
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    public app: AppComponent,
    private router: Router,
    private rest: RestService,
    private formDataService: FormDataService,
    private formBuilder: FormBuilder,
    private seo: ServiceSEOService,
    config: NgbDatepickerConfig,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private el: ElementRef,
    private imageCompressor: NgxImageCompressService,
    private route: ActivatedRoute
  ) {
    this.seo.addMetaTags()
    config.minDate = { year: 1900, month: 1, day: 1 }
    this.selectedLang = this.app.SelectedLang
    setInterval(() => {
      console.log("Verify Token");
      const authInfo: any = JSON.parse(
        this.localStorage.getItem(environment.localAuthenInfo)
      );
      this.verifyToken(authInfo.token);
    }, 300 * 1000);
  }
  width: number
  height: number

  @HostListener("focus", ["$event.target"])
  onFocus(event: any) {
    if (!environment.production) {
      console.log(
        "FOCUS",
        `inputId: ${event.target.id} = ${event.target.value}`
      )
    }

    this.data.session_id = this.localStorage.getItem("session_id")
    this.data.input_id = event.target.id
    this.data.input_value = event.target.value

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when focus input:", res);
    // });
  }

  @HostListener("blur", ["$event.target"])
  onBlur(event: any) {
    this[`${event.target.id}_touch`] = true
    if (!environment.production)
      console.log(
        "BLUR",
        `inputId: ${event.target.id} = ${event.target.value}`
      )

    this.data.session_id = this.localStorage.getItem("session_id")
    this.data.input_id = event.target.id
    this.data.input_value = event.target.value

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when unfocus input:", res);
    // });
  }

  @HostListener("input", ["$event"])
  inputOnChange(event: any) {
    if (event.target.id === "tel") {
      const filterValues = this.filterValue(event.target.value)
      this.person.tel = filterValues
    }
  }

  filterValue(value): string {
    return value.replace(/[^(0-9+)]+/g, "")
  }

  private convertFileToBase64(
    file: File
  ): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  private convertBlobToBase64(
    file: Blob
  ): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  async fileChangeEvent(event: any): Promise<void> {
    console.log("==>", event.target.files[0])
    if (event.target.files[0].size > 10485760) {
      this.updateSessionTrack(event.target.id, "photo_over_10_mb")
      this.isPhotoBigSize = true
      return
    } else {
      this.isPhotoBigSize = false;
      this.imageChangedEvent = event;
      this.photo_count = 1;
      const base64String = await this.convertFileToBase64(
        event.target.files[0]
      );
      // const formData = new FormData();
      // formData.append("upload_file_photo", `${base64String}`);

      this.rest
        .detectFace({ image: base64String })
        .subscribe(async (result) => {
          const faceDetect = Array.isArray(result)
            ? result[0].faces
            : result.faces;

          if (faceDetect.faces === 0) {
            this.facePhotoMessage = faceDetect.message;
          } else if (faceDetect.faces === 1) {
            this.facePhotoMessage = "";
          } else if (faceDetect.faces > 1) {
            this.facePhotoMessage = faceDetect.message;
          }

          console.log("facePhotoMessage", this.facePhotoMessage);
        });
    }
  }
  async fileChangeEventPass(event: any): Promise<void> {
    if (event.target.files[0].size > 10485760) {
      this.updateSessionTrack(event.target.id, "passport_over_10_mb")
      this.isPhotoBigSize = true
      this.isPassportBigSize = true
      return
    } else {
      this.imgPass = event.target.files[0]
      this.isPassportBigSize = false
      this.imageChangedEventPass = event
      this.passport_count = 1

      const base64String = await this.convertFileToBase64(
        event.target.files[0]
      );
      // const formData = new FormData();
      // formData.append("upload_file_passport", `${base64String}`);

      this.rest
        .detectFace({ image: base64String })
        .subscribe(async (result) => {
          const faceDetect = Array.isArray(result)
            ? result[0].faces
            : result.faces;

          if (faceDetect.faces === 0) {
            this.facePassMessage = faceDetect.message;
          } else if (faceDetect.faces === 1) {
            this.facePassMessage = "";
          } else if (faceDetect.faces > 1) {
            this.facePassMessage = faceDetect.message;
          }

          console.log("facePassMessage", this.facePassMessage);
        });
    }
  }

  onRaceChange(event: MatRadioChange) {
    this.person.race = Number(event.value)
    if (event.value == 0) {
      this.person.race = 0
      this.person.family_side = ""
      this.person.family_member_names = ""
      this.person.family_relation = ""
      this.family_side_touch = false
      this.family_member_names_touch = false
      this.family_relation_touch = false
      this.removeProofFile()
    } else {
      this.person.race = 304
    }
  }

  proofFileChangeEvent(e: any): void {
    this.proofFileCount = 1
    const file = e.target.files[0]
    this.proofFileName = file.name
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.proofFile = reader.result
      this.proofFilePreview = reader.result
      this.person.proof_file_preview = reader.result.toString()
    }
  }

  removeProofFile() {
    this.proofFile = ""
    this.proofFilePreview = ""
    this.proofFileName = ""
    this.proofFileCount = 0
  }

  async onUploadProofFile() {
    const formData = new FormData()
    formData.append("prooffile", this.proofFile)
    if (this.proofFile) {
      const result = await this.rest.uploadProofFileService(formData)
      if (result.status == "success") {
        this.person.proof_of_laotian = result.name
        this.personForm.value.proof_of_laotian = result.name
      }
    }
  }

  fileChangeEventPassCover(event: any): void {
    if (event.target.files[0].size > 2097152) {
      this.isPassportCoverBigSize = true
      return
    } else {
      this.isPassportCoverBigSize = false
      this.imageChangedEventPassCover = event
      this.passport_cover_count = 1
    }
  }
  cropperReadyLog() {
    console.log("Cropper ready")
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
  }
  imageCroppedPass(event: ImageCroppedEvent) {
    this.croppedImagePass = event.base64
  }
  imageCroppedPassCover(event: ImageCroppedEvent) {
    this.croppedImagePassCover = event.base64
  }
  imageLoaded() {
    this.cropperReady = true
  }
  imageLoadedPass() {
    this.cropperReadyPass = true
  }
  imageLoadedPassCover() {
    this.cropperReadyPassCover = true
  }
  loadImageFailed() {
    // console.log('Load failed');
  }
  loadImageFailedPass() {
    // console.log('Load failed');
  }
  loadImageFailedPassCover() {
    // console.log('Load failed');
  }

  rotateLeft(img: "photo" | "passport") {
    console.log(this.photoCropper);
    console.log(this[`${img}Cropper`]);
    this[`${img}Cropper`].rotateLeft();
  }

  rotateRight(img: "photo" | "passport") {
    this[`${img}Cropper`].rotateRight()
  }

  flipHorizontal(img: "photo" | "passport") {
    this[`${img}Cropper`].flipHorizontal()
  }

  flipVertical(img: "photo" | "passport") {
    this[`${img}Cropper`].flipVertical()
  }

  verifyToken(credential) {
    this.rest.verifyToken(credential).subscribe(
      (result) => {
        if (
          !result["auth"] &&
          result.message === "Failed to authenticate token."
        ) {
          this.router.navigate(["/apply"], { replaceUrl: true })
          localStorage.removeItem(environment.localAuthenInfo)
        }
        if (!result["auth"] && result.message === "TokenExpired") {
          this.router.navigate(["/apply"], { replaceUrl: true })
          localStorage.removeItem(environment.localAuthenInfo)
        }
        if (!result["responseStatus"]) {
          localStorage.removeItem(environment.localAuthenInfo)
          this.router.navigate(["/apply"], { replaceUrl: true })
        } else {
          localStorage.setItem(
            environment.localAuthenInfo,
            JSON.stringify(result)
          );
          console.log("Back to Applicaiton form page");
          // this.router.navigate(["/application"]);
        }
      },
      (error) => {
        console.error(error)
        localStorage.removeItem(environment.localAuthenInfo)
        this.router.navigate(["/apply"], { replaceUrl: true })
      }
    )
  }

  getCountryList() {
    this.rest.getCountry().subscribe(async (result) => {
      // TODO: changed on v2
      // this.mDataArrayCountry = result.country;
      this.mDataArrayCountry = result
    })
  }

  getCountryBirthList() {
    this.rest.getCountryBirth().subscribe((result) => {
      // TODO: changed on v2
      // this.mDataArrayCountryBirth = result.country;
      this.mDataArrayCountryBirth = result
    })
  }

  getVisaTypeList() {
    this.rest.getVisaType().subscribe(async (result) => {
      this.mDataArrayVisaType = await result
    })

    console.log("mDataArrayVisaType 2", this.mDataArrayVisaType)
  }

  getPortEntryList() {
    this.rest.getPortEntry().subscribe((result) => {
      this.mDataArrayPortEntry = result
      this.filteredPortOfEntry = result
    })
  }
  getPurposeList() {
    this.rest.getPurpose().subscribe((result) => {
      this.mPurpose = result
    })
  }
  getCallingCodeList() {
    this.rest.getCallingCode().subscribe((result) => {
      this.mDataArrayCallingCode = result
    })
  }
  getExceptMessage() {
    this.rest.getExceptMessage().subscribe((result) => {
      setTimeout(() => {
        this.except_message = result["except_message"]
      })
    })
  }

  getPersonInfo(headers) {
    this.rest.checkExistData(headers).subscribe((rs) => {});
  }

  deletePerson(application_id) {
    const id = application_id[0].application_id

    this.rest.deletePerson(id).subscribe((result) => {});
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e: any) => {
        if (event.target.getAttribute("name") === "inputPassportFile") {
          this.person.imgPass = e.target.result
        } else if (event.target.getAttribute("name") === "inputPhotoFile") {
          this.person.imgPhoto = e.target.result
        }
      }
      reader.readAsDataURL(file)
      // reader.readAsDataURL(event.target.files[0]);
    }
  }
  save(form: FormGroup): boolean {
    if (!form.valid) {
      return false
    }
    this.person.first_action_date = ""
    this.formDataService.setPerson(this.person)
    this.router.navigate(["/review"])
    return true
  }

  goToNext(form: any) {
    if (this.save(form)) {
      // Navigate to the work page
      this.router.navigate(["/review"])
    }
  }
  IntendedOnChange(event) {
    this.intendedDate = event;
    this.validateIntendedDate(event);
    console.log("Intended: ", event);
  }
  validateIntendedDate(event) {
    if (this.expiredDate && this.intendedDate) {
      const inten = new Date(
        this.intendedDate.year,
        this.intendedDate.month - 1,
        this.intendedDate.day
      )
      const ex = new Date(
        this.expiredDate.year,
        this.expiredDate.month - 1,
        this.expiredDate.day
      )

      const intended = moment(inten)
      const expired = moment(ex)
      const diff = expired.diff(intended, "months")

      if (diff >= 6) {
        this.isIntendedError = false
      } else {
        // if(this.app.SelectedLang == 'en'){
        this.intentedMessage =
          "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure."
        this.intentedMessage_cn =
          "护照有效期不得少于6个月，将从入境老挝之日起开始计算。入境日期不得晚于申请电子签证后60天。建议您至少在出发前 5 天提出申请。"
        this.intentedMessage_fr =
          "The passport should have minimum validity of 6 months from the date of arrival in Laos."

        this.isIntendedError = true
        return
      }
    }
    if (this.intendedDate) {
      const inten = new Date(
        this.intendedDate.year,
        this.intendedDate.month - 1,
        this.intendedDate.day
      )
      const intended = moment(inten)
      const now = new Date()
      const checkintenDate = intended.diff(now, "days") + 1
      // console.log('check if intended not more 7 day ' + checkintenDate);

      if (checkintenDate < 5) {
        // if (this.intendedDate.day > 0) {
        // if(this.app.SelectedLang == 'en'){
        this.intentedMessage =
          "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure."
        this.intentedMessage_cn =
          "护照自抵达老挝之日起至少应有 6 个月的有效期。 您的预计入境日期应在电子签证申请之日起 60 天内。 建议您至少在出发前 5 天提出申请。"
        this.intentedMessage_fr =
          "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure."

        this.isIntendedError = true
        // }

        return
      } else {
        this.isIntendedError = false
      }
    }
  }
  DateBirthOnChange(event: any) {
    if (this.initialLoadDob) {
      this.initialLoadDob = false
      return
    }
    this.setFormLocalStorage.dateBirth = event
    this.onSetToLocalStorage(this.setFormLocalStorage)
    this.person.DOB = event
  }

  ExpiredOnChange(event: any) {
    if (this.initialLoadExpire) {
      this.initialLoadExpire = false
      return
    }
    this.setFormLocalStorage.dateExpire = event
    this.onSetToLocalStorage(this.setFormLocalStorage)
    this.person.expiredDate = event
    this.expiredDate = event
    const curent_date = new Date()
    const ex = new Date(
      this.expiredDate.year,
      this.expiredDate.month - 1,
      this.expiredDate.day
    )
    const now = moment(curent_date)
    const expired = moment(ex)
    const diff = expired.diff(now, "days")
    if (diff <= 0) {
      if (this.expiredDate.day > 0) {
        this.isExpiredDate = true
      }
    } else {
      this.isExpiredDate = false
      this.validateIntendedDate(event)
    }
  }

  IssueOnChange(event) {
    if (this.initialLoadIssue) {
      this.initialLoadIssue = false
      return
    }
    this.issueDate = event
    this.setFormLocalStorage.dateIssue = event
    this.onSetToLocalStorage(this.setFormLocalStorage)
    if (this.issueDate && this.expiredDate) {
      const curent_date = new Date()
      const ex = new Date(
        this.expiredDate.year + 10,
        this.expiredDate.month - 1,
        this.expiredDate.day
      )
      const now = moment(curent_date)
      const expired = moment(ex)
      const diff = expired.diff(now, "days")
      if (diff <= 0) {
        if (this.expiredDate.day > 0) {
          this.isExpiredDate = true
        }
      } else {
        this.isExpiredDate = false
        this.validateIntendedDate(event)
      }
    }
  }

  onCountryBirthChange(selectedId: number) {
    this.setFormLocalStorage.countryBirth = Number(selectedId)
    this.onSetToLocalStorage(this.setFormLocalStorage)

    console.log("Country of birth:", selectedId)
    const s: string = this.mDataArrayCountryBirth.find(
      (x: any) => x.country_id == selectedId
    )
    this.person.selectedCountryBirth = s["country_name"]
    console.log("Country Birth:", s)
    // this.updateSessionTrack("countryOfBirth", `${selectedId}`);
  }

  onPurposeChange(selectedId: number) {
    console.log("Purpose of trip: ", selectedId)
    this.setFormLocalStorage.purposeTrip = Number(selectedId)
    this.onSetToLocalStorage(this.setFormLocalStorage)
    const s: string = this.mPurpose.find(
      (x: any) => x.purpose_id == selectedId
    )
    console.log(s)
    this.person.selectedPurpose = s["purpose_name"]

    // this.updateSessionTrack("purposeOfTrip", `${selectedId}`);
  }

  onGenderChange(event: Event) {
    const selectedOptions = event.target["options"]
    const selectedIndex = selectedOptions.selectedIndex
    const selectElementText = selectedOptions[selectedIndex].text
    this.person.selectedGender = selectElementText

    this.setFormLocalStorage.gender = selectedOptions[selectedIndex].value
    this.setFormLocalStorage.genderSelect = selectElementText
    this.onSetToLocalStorage(this.setFormLocalStorage)
    console.log(event.target["options"])

    // this.updateSessionTrack("gender", selectElementText);
  }

  onCountryOfCityChange(selectedId, event, key) {
    if (key == "countryCity") {
      this.setFormLocalStorage.countryCity = Number(selectedId)
    } else {
      this.setFormLocalStorage.countryPassport = Number(selectedId)
    }
    this.onSetToLocalStorage(this.setFormLocalStorage)
    console.log("Data Country of City:", selectedId)
    this.updateSessionTrack("countryOfCity", `${selectedId}`)
    if (selectedId !== "") {
      const s: string = this.mDataArrayCountry.find(
        (x: any) => x.country_id == selectedId
      )
      this.person.selectedCountryCity = s["country_name"]
      this.person.selectedCountryOfPassport = s["country_name"]
      this.citizens = s["citizens"]
      this.days = s["stay_day"]
      const country = this.mDataArrayCountry.find(
        (x) => x.country_id == selectedId
      )
      if (country.is_except == 1) {
        event.srcElement.blur()
        event.preventDefault()
        this.openLg(this.content)
      }
      if (country.request_attach === 1) {
        this.person.is_request_doc = true
      } else if (country.request_attach == 0) {
        this.person.is_request_doc = false
      }
    } else {
      this.person.is_request_doc = false
    }

    // If selected Taiwan (167), hide Boten Railway (53)
    if (selectedId == 167) {
      this.filteredPortOfEntry = this.mDataArrayPortEntry.filter(
        (item) => item.port_of_entry_id != 53
      )
    } else {
      this.filteredPortOfEntry = this.mDataArrayPortEntry
    }
  }
  openLg(content) {
    this.modalService.open(content, { size: "lg", centered: true })
  }
  onCountryOfPassportChange(selectedId, event) {
    console.log("Country Passport:", selectedId)
    if (selectedId !== "") {
      if (this.selectedCountry) {
        this.selectedCountry = selectedId
      }
      const country = this.mDataArrayCountry.find(
        (x) => x.country_id == selectedId
      )
      this.person.selectedCountryOfPassport = country["country_name"]
      if (country.is_except === 1) {
        event.srcElement.blur()
        event.preventDefault()
        this.openLg(this.content)
      }
      if (country.request_attach === 1) {
        this.person.is_request_doc = true
      } else if (country.request_attach == 0) {
        this.person.is_request_doc = false
      }
    } else {
      this.person.is_request_doc = false
    }

    // If selected Taiwan (167), hide Boten Railway (53)
    if (selectedId == 167) {
      this.filteredPortOfEntry = this.mDataArrayPortEntry.filter(
        (item) => item.port_of_entry_id != 53
      )
    } else {
      this.filteredPortOfEntry = this.mDataArrayPortEntry
    }
  }
  onVisaTypeChange(selectedId: number) {
    // this.updateSessionTrack("visaType", `${selectedId}`);
    const s: string = this.mDataArrayVisaType.find(
      (x: any) => x.visa_type_id == selectedId
    )
    this.person.selectedvisaType = s["visa_type_name"]

    // if (this.personRace == 304) {
    //   const s: string = this.mDataArrayVisaType.find(
    //     (x: any) => x.visa_type_id == 2
    //   );
    //   this.person.visaType = "2";
    //   this.person.selectedvisaType = s["visa_type_name"];
    // } else {
    //   const s: string = this.mDataArrayVisaType.find(
    //     (x: any) => x.visa_type_id == selectedId
    //   );
    //   this.person.visaType = "1";
    //   this.person.selectedvisaType = s["visa_type_name"];
    // }
  }
  onPortOfEntryChange(selectedId: number) {
    this.updateSessionTrack("portOfEntry", `${selectedId}`)
    const s: string = this.mDataArrayPortEntry.find(
      (x: any) => x.port_of_entry_id == selectedId
    )
    this.person.selectedportOfEntry = s["port_of_entry_name"]

    // Before 2023-04-07 If select Boten Railway Station, intended date must be >= 13/04/2023
    if (moment().isBefore("2023-04-07") && selectedId == 53) {
      this.minDate.year = Number(moment("2023-04-13").format("YYYY"))
      this.minDate.month = Number(moment("2023-04-13").format("MM"))
      this.minDate.day = Number(moment("2023-04-13").format("DD"))

      this.person.intendedDate = { year: 0, month: 0, day: 0 }
    } else {
      this.minDate.year = Number(moment().add(5, "d").format("YYYY"))
      this.minDate.month = Number(moment().add(5, "d").format("MM"))
      this.minDate.day = Number(moment().add(5, "d").format("DD"))

      // reset intended date
      this.person.intendedDate = { year: 0, month: 0, day: 0 }
    }
  }

  onSubmit(form: FormGroup) {
    console.log("Person Form:", this.person);
    this.submitted = true;
    // if (!this.custImage.imgPhoto
    //   || this.facePhoto === 0 || this.facePhoto > 1 || this.facePhotoMessage.length > 0
    // ) {
    //   return;
    // }
    // if (!this.custImage.imgPass
    //   || this.facePassport === 0 || this.facePassport > 1 || this.facePassMessage.length > 0
    // ) {
    //   return;
    // }

    if (!this.person.imgPhotoName) {
      this.custImage.imgPhoto = null
      return
    }
    if (!this.person.imgPassName) {
      this.custImage.imgPass = null
      return
    }

    if (!this.person.countryCity) {
      return
    }

    if (this.person.is_request_doc === true) {
      if (this.person.hotel_doc === "" || this.hotel_doc_big_size === true) {
        return
      }
      if (this.person.flight_doc === "" || this.flight_doc_big_size === true) {
        return
      }
      if (
        this.person.third_visa_doc === "" ||
        this.third_visa_doc_big_size === true
      ) {
        return
      }
      if (
        this.person.hotel_doc === "" ||
        this.guarantor_visa_doc_big_size === true
      ) {
        return
      }
      if (
        this.person.bank_statement_doc === "" ||
        this.bank_statement_doc_big_size === true
      ) {
        return
      }
    } else if (this.save(form)) {
      this.router.navigate(["/review"])
    }
  }
  checkAccept() {
    this.acceptTerm = !this.acceptTerm
  }

  async onUploadPhotoImage() {
    // this.person.imgPhoto = this.croppedImage;
    this.custImage.imgPhoto = this.croppedImage
    // this.setFormLocalStorage.photoBase64 = this.croppedImage;
    // this.onSetToLocalStorage(this.setFormLocalStorage);
    const formData = new FormData()
    formData.append("upload_file_photo", this.custImage.imgPhoto)
    formData.append("version", "2")

    await this.rest.uploadPhotoFile(formData).subscribe(async (result) => {
      if (Array.isArray(result)) {
        this.person.imgPhotoName = result[0].name;
        this.setFormLocalStorage.photoImg = result[0].name;
        this.setFormLocalStorage.photoImgUrl = `${
          environment.node_image_url
        }/photo/${dayjs().format("YYYYMMDD")}/${result[0].name}`;
        this.onSetToLocalStorage(this.setFormLocalStorage);
        console.log("==><==", result[0]);
        this.facePhoto = result[0].faces;
      } else {
        this.person.imgPhotoName = result.name;
        this.setFormLocalStorage.photoImg = result.name;
        this.setFormLocalStorage.photoImgUrl = `${
          environment.node_image_url
        }/photo/${dayjs().format("YYYYMMDD")}/${result.name}`;
        this.onSetToLocalStorage(this.setFormLocalStorage);
        console.log("==><==", result);
        this.facePhoto = result.faces;
      }
    })
  }

  async onUploadPassportImage() {
    this.spinner.show()
    setTimeout(() => this.spinner.hide(), 3000)
    // this.person.imgPass = this.croppedImagePass;

    this.custImage.imgPass = this.croppedImagePass
    this.mrzPass = this.imgPass
    const event = this.imageChangedEventPass
    const formData = new FormData()
    // this.setFormLocalStorage.passportBase64 = this.croppedImagePass;
    // this.onSetToLocalStorage(this.setFormLocalStorage);
    formData.append("upload_file_passport", this.custImage.imgPass)
    await this.rest.uploadPassportFile(formData).subscribe(async (result) => {
      // console.log(result.name);
      // console.log(result.mrz);
      this.person.imgPassName = result.name;
      this.setFormLocalStorage.passportImg = result.name;
      this.setFormLocalStorage.passportImgUrl = `${
        environment.node_image_url
      }/passport/${dayjs().format("YYYYMMDD")}/${result.name}`;
      this.onSetToLocalStorage(this.setFormLocalStorage);
      setTimeout(() => {
        this.updateSessionTrack("upload_passport", "upload_passport_success")
      }, 1500)

      if (result.mrz !== null) {
        if (result.mrz.status == "success") {
          this.mrzData = result.mrz.data.passport
          if (this.mrzData.lastName)
            this.person.lastName = this.mrzData.lastName
          this.setFormLocalStorage.lastName = this.mrzData.lastName
          if (this.mrzData.firstName)
            this.person.firstName = this.mrzData.firstName
          this.setFormLocalStorage.firstName = this.mrzData.firstName

          if (this.mrzData.passportNumber)
            this.person.passportNumber = this.mrzData.passportNumber
          this.setFormLocalStorage.passportNum = this.mrzData.passportNumber

          if (this.mrzData.sex)
            this.person.gender = `${this.mrzData.sex}`.toLowerCase()
          if (result.mrz.data.passport)
            this.expDate = result.mrz.data.passport.expirationDate.split("-")
          if (this.mrzData.birthDate)
            this.dateBirth = this.mrzData.birthDate.split("-")
          if (this.mrzData.countryOfCitizenshipId)
            this.person.countryCity = this.mrzData.countryOfCitizenshipId
          if (this.mrzData.countryOfPassportId)
            this.person.countryOfPassport = this.mrzData.countryOfPassportId

          this.ExpiredOnChange({
            year: parseInt(this.expDate[0]),
            month: parseInt(this.expDate[1]),
            day: parseInt(this.expDate[2]),
          })
          this.DateBirthOnChange({
            year: parseInt(this.dateBirth[0]),
            month: parseInt(this.dateBirth[1]),
            day: parseInt(this.dateBirth[2]),
          })
          this.onCountryOfCityChange(
            this.mrzData.countryOfCitizenshipId,
            "",
            "nothing"
          )
          this.onCountryOfPassportChange(this.mrzData.countryOfPassportId, "")
        }
      }

      if (result.faces != null) {
        console.log("==><==", result.faces);
        this.facePassport = result.faces;
      }
    })

    // this.mrzDetection(this.mrzPass)
  }

  // mrzDetection(image) {
  //   this.spinner.show();
  //   setTimeout(() => this.spinner.hide(), 3000);
  //   const formdata = new FormData();
  //   formdata.append('image', image);
  //   this.rest.ocrMRZDetection(formdata).subscribe(async (result) => {
  //     if (result.status === 'failed') {
  //       console.log('Result from MRZ:', result);
  //       return
  //     }
  //     if (result.status === 'success') {
  //       this.mrzData = await result.data.passport;
  //       this.person.lastName = this.mrzData.lastName;
  //       this.person.firstName = this.mrzData.firstName;
  //       this.person.passportNumber = this.mrzData.passportNumber;
  //       this.person.gender = `${this.mrzData.sex}`.toLowerCase();
  //       this.expDate = await result.data.passport.expirationDate.split('-');
  //       this.dateBirth = this.mrzData.birthDate.split('-');
  //       this.person.countryCity = this.mrzData.countryOfCitizenshipId;
  //       this.person.countryOfPassport = this.mrzData.countryOfPassportId;
  //     }
  //     this.ExpiredOnChange({ year: parseInt(this.expDate[0]), month: parseInt(this.expDate[1]), day: parseInt(this.expDate[2]) });
  //     this.DateBirthOnChange({ year: parseInt(this.dateBirth[0]), month: parseInt(this.dateBirth[1]), day: parseInt(this.dateBirth[2]) })
  //     this.onCountryOfCityChange(this.mrzData.countryOfCitizenshipId, '');
  //     this.onCountryOfPassportChange(this.mrzData.countryOfPassportId, '');
  //   });
  // }

  onUploadPassportCoverImage() {
    // this.person.imgPass = this.croppedImagePass;
    this.custImage.imgCover = this.croppedImagePassCover
    const event = this.imageChangedEventPassCover
    const formData = new FormData()
    formData.append("upload_file_passport_cover", this.custImage.imgCover)
    this.rest.uploadPassportCoverFile(formData).subscribe(async (result) => {
      this.person.imgCoverName = await result[0].name
    })
  }
  onFileHotelChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.hotel_doc_big_size = true
    } else {
      this.hotel_doc_big_size = false
      this.selectedFile = event.target.files[0]
      this.person.hotel_doc = event.target.files[0].name
      // console.log(this.person.hotel_doc);
      this.onUpload()
    }
  }
  onFileFlightChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.flight_doc_big_size = true
    } else {
      this.flight_doc_big_size = false
      this.selectedFileFlight = event.target.files[0]
      this.onUploadFlight()
    }
  }

  onFileThirdVisaChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.third_visa_doc_big_size = true
    } else {
      this.third_visa_doc_big_size = false
      this.selectedFileThirdVisa = event.target.files[0]
      this.person.third_visa_doc = event.target.files[0].name
      this.onUploadThirdVisa()
    }
  }

  onFileGuarantorInputUploadChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.guarantor_visa_doc_big_size = true
    } else {
      this.guarantor_visa_doc_big_size = false
      this.selectedFileGuarantor = event.target.files[0]
      this.person.guarantor_doc = event.target.files[0].name
      this.onUploadGuarantor()
    }
  }

  onFileBankStatementChanged(event) {
    if (event.target.files[0].size > 2097152) {
      this.bank_statement_doc_big_size = true
    } else {
      this.bank_statement_doc_big_size = false
      this.selectedFileBankStatement = event.target.files[0]
      this.person.bank_statement_doc = event.target.files[0].name
      this.onUploadBankStatement()
    }
  }

  onUpload() {
    const uploadData = new FormData()
    uploadData.append("hotel_file", this.selectedFile, this.selectedFile.name)
    this.rest.uploadHoteldoc(uploadData).subscribe((result) => {
      this.person.hotel_doc = result.name
    })
  }
  onUploadFlight() {
    const uploadData = new FormData()
    uploadData.append(
      "flight_file",
      this.selectedFileFlight,
      this.selectedFileFlight.name
    )
    this.rest.uploadFlightdoc(uploadData).subscribe((result) => {
      this.person.flight_doc = result.name
    })
  }
  onUploadThirdVisa() {
    const uploadData = new FormData()
    uploadData.append(
      "third_visa_file",
      this.selectedFileThirdVisa,
      this.selectedFileThirdVisa.name
    )
    this.rest.uploadThirdVisadoc(uploadData).subscribe((result) => {
      this.person.third_visa_doc = result.name
    })
  }
  onUploadGuarantor() {
    const uploadData = new FormData()
    uploadData.append(
      "guarantor_file",
      this.selectedFileGuarantor,
      this.selectedFileGuarantor.name
    )
    this.rest.uploadGuarantordoc(uploadData).subscribe((result) => {
      this.person.guarantor_doc = result.name
    })
  }
  onUploadBankStatement() {
    const uploadData = new FormData()
    uploadData.append(
      "bank_statement_file",
      this.selectedFileBankStatement,
      this.selectedFileBankStatement.name
    )
    this.rest.uploadBankStatementdoc(uploadData).subscribe((result) => {
      this.person.bank_statement_doc = result.name
    })
  }

  // convenience getter for easy access to form fields
  get f(): any {
    return this.personForm.controls
  }

  ngAfterViewInit(): void {
    console.log("Person Form:", this.person);
    const authInfo = JSON.parse(
      this.localStorage.getItem(environment.localAuthenInfo)
    )

    const checkValidDate = this.setFormLocalStorage.checkExpDate
    setTimeout(() => {
      const currentDate = dayjs()
      const dayDiff = currentDate.diff(checkValidDate, "days")
      if (dayDiff > 7) {
        this.localStorage.removeItem("previousData")
      } else {
        this.person.lastName = this.setFormLocalStorage.lastName
        this.person.firstName = this.setFormLocalStorage.firstName
        this.person.selectedGender = this.setFormLocalStorage.genderSelect
        this.person.gender = this.setFormLocalStorage.gender
        // this.custImage.imgPhoto = this.setFormLocalStorage.photoBase64;
        // this.custImage.imgPass = this.setFormLocalStorage.passportBase64;
        // this.reUploadPhoto(this.custImage.imgPhoto);
        // this.reUploadPassport(this.custImage.imgPass);
        this.person.imgPhotoName = this.setFormLocalStorage.photoImg
        this.person.imgPassName = this.setFormLocalStorage.passportImg
        this.DateBirthOnChange(this.setFormLocalStorage.dateBirth)
        this.IssueOnChange(this.setFormLocalStorage.dateIssue)
        this.ExpiredOnChange(this.setFormLocalStorage.dateExpire)
        this.person.tel = this.setFormLocalStorage.telephone
        this.person.occupation = this.setFormLocalStorage.occupation
        this.person.resAddress = this.setFormLocalStorage.residentialAddress
        this.person.province = this.setFormLocalStorage.stateProvince
        this.person.issueDate = this.setFormLocalStorage.dateIssue
        this.person.passportNumber = this.setFormLocalStorage.passportNum
        this.person.vehicle = this.setFormLocalStorage.vehicle
        this.person.addressDuringVisit =
          this.setFormLocalStorage.addressDuringVisit
        this.person.next_destination = this.setFormLocalStorage.nextDestination
      }
    }, 2000)
    if (authInfo) {
      this.person.token = authInfo.token
      this.verifyToken(authInfo.token)
      this.getPersonInfo(authInfo.token)
      this.getCountryList()
      this.getCountryBirthList()
      this.getVisaTypeList()
      this.getPortEntryList()
      this.getCallingCodeList()
      this.getPurposeList()
      this.getExceptMessage()
      const localAuth = localStorage.getItem("localAuthenInfo")

      setTimeout(() => {
        this.person.DOB = this.setFormLocalStorage.dateBirth
        if (this.setFormLocalStorage.countryBirth !== null) {
          const s: string = this.mDataArrayCountryBirth.find(
            (x: any) => x.country_id === this.setFormLocalStorage.countryBirth
          )
          this.person.countryBirth = s["country_id"]
          this.person.selectedCountryBirth = s["country_name"]
        }

        if (this.setFormLocalStorage.countryCity !== null) {
          const s: string = this.mDataArrayCountry.find(
            (x: any) => x.country_id === this.setFormLocalStorage.countryCity
          )
          this.person.countryCity = s["country_id"]
          this.person.selectedCountryCity = s["country_name"]
        }
        if (this.setFormLocalStorage.countryPassport !== null) {
          const s: string = this.mDataArrayCountry.find(
            (x: any) =>
              x.country_id === this.setFormLocalStorage.countryPassport
          )
          this.person.countryOfPassport = s["country_id"]
          this.person.selectedCountryOfPassport = s["country_name"]
        }
        if (this.setFormLocalStorage.purposeTrip !== null) {
          const s: string = this.mPurpose.find(
            (x: any) => x.purpose_id === this.setFormLocalStorage.purposeTrip
          )
          this.person.purposeOfTrip = s["purpose_id"]
          this.person.selectedPurpose = s["purpose_name"]
        }

        if (
          this.person.countryCity == "167" ||
          this.person.countryOfPassport == "167"
        ) {
          this.filteredPortOfEntry = this.mDataArrayPortEntry.filter(
            (item) => item.port_of_entry_id != 53
          )
        } else {
          this.filteredPortOfEntry = this.mDataArrayPortEntry
        }
      }, 3000)

      if (localAuth !== null && localAuth !== "null") {
        const authUser = JSON.parse(localAuth)
        const token = authUser["token"]
        const payload = token.split(".")[1]
        const payloadObj = JSON.parse(atob(payload))["email"]

        // setTimeout(() => {
        //   this.rest
        //     .updateUserSession({
        //       email: payloadObj,
        //       session_id: this.localStorage.getItem("session_id"),
        //     })
        //     .subscribe((res) => {
        //       console.log("Result Update Session when focus input:", res);
        //     });
        // }, 1500);
      }
    } else {
      this.router.navigate(["apply"])
    }
  }

  // @TraceMethodDecorator()
  ngOnInit() {
    this.sessionId = localStorage.getItem("session_id")
    this.onSetDateLaoNewYear()
    this.setFormLocalStorage = this.localStorage.getItem("previousData")
      ? JSON.parse(this.localStorage.getItem("previousData"))
      : this.setFormLocalStorage
    //  this.minDate.year = Number(moment().add(5, "d").format("YYYY"));
    //  this.minDate.month = Number(moment().add(5, "d").format("MM"));
    //  this.minDate.day = Number(moment().add(5, "d").format("DD"));
    this.today.year = Number(moment().format("YYYY"))
    this.today.month = Number(moment().format("MM"))
    this.today.day = Number(moment().format("DD"))
    this.next_2month.year = Number(moment().add(60, "d").format("YYYY"))
    this.next_2month.month = Number(moment().add(60, "d").format("MM"))
    this.next_2month.day = Number(moment().add(60, "d").format("DD"))
    this.personForm = this.formBuilder.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z ,-.']*")],
      ],
      lastName: ["", [Validators.pattern("[a-zA-Z ,-.']*")]],
      gender: ["", [Validators.required]],
      DOB: ["", [Validators.required]],
      countryBirth: ["", [Validators.required]],
      tel: ["", [Validators.required, Validators.pattern("^[0-9+]{6}[0-9]+$")]],
      occupation: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      resAddress: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ',.#]*")],
      ],
      province: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      countryCity: ["", [Validators.required]],
      next_destination: [" ", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
      passportNumber: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
      ],
      issueDate: ["", [Validators.required]],
      expiredDate: ["", [Validators.required]],
      countryOfPassport: ["", [Validators.required]],
      visaType: ["1", [Validators.required]],
      intendedDate: ["", [Validators.required]],
      vehicle: ["", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
      purposeOfTrip: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
      ],
      addressDuringVisit: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 ',]*")],
      ],
      acceptTerm: ["", [Validators.required]],
      myfileFlight: ["", []],
      myfileHotel: ["", []],
      ThirdCountryVisa: ["", []],
      guarantor: ["", []],
      bank_statement: ["", []],
    })

    // alert(this.cookieService.get('p_id'));
    this.person = this.formDataService.getFormData()
    this.custImage = this.formDataService.getFormData()
    this.person.currentActiveForm = "1"
    $(document).ready(function () {
      $("#warningUploadPassport").hide()
      $("#warningUploadPhoto").hide()
      $("#btnContinue").click(function () {
        if ($("#inputPassportFile")[0].files.length === 0) {
          $("#warningUploadPassport").show()
        }
        if ($("#inputPhotoFile")[0].files.length === 0) {
          $("#warningUploadPhoto").show()
        }
      })
      $("#btnshowModalPassport").click(function () {
        $("#warningUploadPassport").hide()
      })
      $("#btnshowModalPhoto").click(function () {
        $("#warningUploadPhoto").hide()
      })
      $("#btnshowModalPassportCover").click(function () {
        $("#warningUploadPassportCover").hide()
      })
    })
  }

  onActivate(event) {
    this.window.scroll(0, 0)
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid")

    // firstInvalidControl.focus(); //without smooth behavior
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth",
    })
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset
  }

  checkDuplicate() {
    const authInfo = JSON.parse(
      this.localStorage.getItem(environment.localAuthenInfo)
    )
    if (this.personForm.invalid) {
      this.personForm.markAllAsTouched()
      this.scrollToFirstInvalidControl()
    }
    const { passportNumber, countryOfPassport } =
      this.formDataService.getPerson()
    this.verifyToken(authInfo.token)
    this.rest
      .CheckDuplicate({
        passportNumber,
        countryOfPassport,
      })
      .subscribe((result) => {
        if (result.status == "success") {
          this.onSubmit(this.personForm)
          this.data.session_id = this.localStorage.getItem("session_id")
          this.data.input_id = "NextToReview"
          this.data.input_value = "continue_to_review"

          // this.rest.updateUserSession(this.data).subscribe((res) => {
          //   console.log("Result Update Session:", res);
          // });
        } else {
          this.dialog
            .open(PopUpComponent, {
              data: result.data,
              panelClass: "mat-popup-container",
            })
            .afterClosed()
            .subscribe((confirm) => {
              console.log("Confirm duplicate:", confirm)

              if (confirm == "continue") {
                this.onSubmit(this.personForm)
                this.data.session_id = this.localStorage.getItem("session_id")
                this.data.input_id = "NextToReview"
                this.data.input_value = "continue_to_review"

                // this.rest.updateUserSession(this.data).subscribe((res) => {
                //   console.log("Result Update Session:", res);
                // });
              }
            })
        }
      })
  }

  async updateSessionTrack(input: string, value: string) {
    // this.rest
    //   .updateUserSession({
    //     session_id: this.localStorage.getItem("session_id"),
    //     input_id: input,
    //     input_value: value,
    //   })
    //   .subscribe((res) => {
    //     console.log("Result Update Session:", res);
    //   });
  }

  clickPopUp() {
    const data = [
      {
        application_id: "KK14OMT",
        country_of_passport: 10,
        created_date: "2024-01-25T04:49:27.000Z",
        expired_date: "2028-03-11T17:00:00.000Z",
        intended_date: "2024-01-30T17:00:00.000Z",
        issue_date: "2024-01-24T17:00:00.000Z",
        passport_number: "MEQU2875497",
        status: "Active",
        visa_status: "Pending",
      },
      {
        application_id: "KK14OMT",
        country_of_passport: 10,
        created_date: "2024-01-25T04:49:27.000Z",
        expired_date: "2028-03-11T17:00:00.000Z",
        intended_date: "2024-01-30T17:00:00.000Z",
        issue_date: "2024-01-24T17:00:00.000Z",
        passport_number: "MEQU2875497",
        status: "Active",
        visa_status: "Pending",
      },
    ]
    console.log("Click me!")
    this.dialog
      .open(PopUpComponent, {
        data,
        panelClass: "mat-dialog-container",
      })
      .afterClosed()
      .subscribe((confirm) => {
        console.log("Confirm duplicate:", confirm)
      })
  }

  onSetDateLaoNewYear() {
    const dateAdd =
      {
        "2024-04-09": 15,
        "2024-04-10": 14,
        "2024-04-11": 13,
        "2024-04-12": 12,
        "2024-04-13": 11,
        "2024-04-14": 10,
        "2024-04-15": 9,
        "2024-04-16": 8,
        "2024-04-17": 7,
        "2024-04-18": 6,
      }[moment().format("YYYY-MM-DD")] || 5

    const newDate = moment().add(dateAdd, "days")
    this.minDate.year = Number(newDate.format("YYYY"))
    this.minDate.month = Number(newDate.format("MM"))
    this.minDate.day = Number(newDate.format("DD"))
  }

  onSetUnfinishedForm(event: any, key: string) {
    if (key == "firstName") {
      this.setFormLocalStorage.firstName = event.target.value
    } else if (key == "lastName") {
      this.setFormLocalStorage.lastName = event.target.value
    } else if (key == "telephone") {
      this.setFormLocalStorage.telephone = event.target.value
    } else if (key == "occupation") {
      this.setFormLocalStorage.occupation = event.target.value
    } else if (key == "resAddress") {
      this.setFormLocalStorage.residentialAddress = event.target.value
    } else if (key == "province") {
      this.setFormLocalStorage.stateProvince = event.target.value
    } else if (key == "passportNo") {
      this.setFormLocalStorage.passportNum = event.target.value
    } else if (key == "vehicle") {
      this.setFormLocalStorage.vehicle = event.target.value
    } else if (key == "addrDuringVisit") {
      this.setFormLocalStorage.addressDuringVisit = event.target.value
    } else if (key == "nextDestination") {
      this.setFormLocalStorage.nextDestination = event.target.value
    }

    this.onSetToLocalStorage(this.setFormLocalStorage)
  }

  onSetToLocalStorage(data: any) {
    this.localStorage.setItem("previousData", JSON.stringify(data))
  }

  reUploadPhoto(base64: any) {
    const formData = new FormData()
    formData.append("upload_file_photo", base64)
    formData.append("version", "2")

    this.rest.uploadPhotoFile(formData).subscribe(async (result) => {
      if (Array.isArray(result)) {
        this.person.imgPhotoName = result[0].name
      } else {
        this.person.imgPhotoName = result.name
      }
    })
  }
  reUploadPassport(base64: any) {
    const formData = new FormData()
    formData.append("upload_file_passport", base64)
    this.rest.uploadPassportFile(formData).subscribe(async (result) => {
      this.person.imgPassName = result.name
    })
  }

  clickCheckSurname() {
    if (this.personForm.invalid) {
      this.personForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
    if (
      this.person.lastName === "".trim() &&
      this.acceptTerm === true &&
      this.personForm.valid
    ) {
      Swal.fire({
        title:
          "<h6>You did not fill in the 'Surname' field. Please confirm if you do not have a surname. Otherwise, go back and enter your surname before submitting.</h6>",
        input: "checkbox",
        showCancelButton: true,
        inputPlaceholder: `
             I confirm that my passport does not have a surname
          `,
        confirmButtonText: `
            Continue&nbsp;<i class="fa fa-arrow-right"></i>
          `,
        customClass: {
          input: "check-box",
        },
        cancelButtonText: "Go Back",
        inputValidator: (result) => {
          if (!result) {
            return !result && "You need to agree first!";
          } else {
            this.checkDuplicate();
          }
        },
      });
    } else {
      this.checkDuplicate();
    }
  }
}
