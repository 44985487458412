import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import * as Sentry from "@sentry/angular";

import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import { PopUpComponent } from "./components/pop-up/pop-up.component";
import { RestService } from "./services/rest.service";
declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "Lao Official Online Visa - Get your Laos Visa online - Lao eVisa";
  SelectedLang = environment.defaultLang;
  popupCheckData = [];
  // iso_code: string;

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private rest: RestService
  ) {
    if (
      localStorage.getItem(environment.selectedLang) === "" ||
      localStorage.getItem(environment.selectedLang) === null
    ) {
      // localStorage.setItem(environment.selectedLang, "en");
      // translateService.setDefaultLang("en");
      localStorage.setItem(environment.selectedLang, this.SelectedLang);
      translateService.setDefaultLang(this.SelectedLang);
    } else {
      this.SelectedLang = localStorage.getItem(environment.selectedLang);
      translateService.setDefaultLang(this.SelectedLang);
    }
  }
  public switchLanguage(language: string) {
    this.SelectedLang = language;
    localStorage.setItem(environment.selectedLang, language);
    this.translateService.use(language);
  }
  ngOnInit(): void {
    // offside('.navbar-menu-mobile-wrapper', {
    //   buttonsSelector: '.navbar-menu-toggle',
    //   slidingSide: 'right'
    // });
    // this.geoIPZoneDetection();
    const sessionId = uuid.v4();
    localStorage.setItem("session_id", sessionId);

    const localAuth = localStorage.getItem("localAuthenInfo");
    if (localAuth !== null && localAuth !== "null") {
      const authUser = JSON.parse(localAuth);
      const token = authUser["token"];
      const payload = token.split(".")[1];
      const payloadObj = JSON.parse(atob(payload))["client_email"];
      Sentry.setUser({ email: payloadObj });
      // const payloadObj = JSON.parse(atob(payload))['email'];
    }

    setTimeout(() => {
      this.rest.addUserSession(sessionId).subscribe((res) => {
        console.log("resopnse from back end:", res);
      });
    }, 1200);
  }
  ngAfterViewInit(): void {
    this.loadScript();
  }

  loadScript() {
    let node = document.createElement("script"); // creates the script tag
    node.type = "text/javascript"; // set the script type
    node.async = true; // makes script run asynchronously
    node.charset = "utf-8";
    if (environment.production) {
      node.innerHTML = `
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["setCookieDomain", "*.laoevisa.gov.la"]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="https://analytics.laogw.la/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '2']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    `;
      // append to head of document
      document.getElementsByTagName("head")[0].appendChild(node);
    }

    // <!-- Matomo -->
    // <script>
    //   var _paq = window._paq = window._paq || [];
    //   /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    //   _paq.push(["setCookieDomain", "*.laoevisa.gov.la"]);
    //   _paq.push(['trackPageView']);
    //   _paq.push(['enableLinkTracking']);
    //   (function() {
    //     var u="https://analytics.laogw.la/";
    //     _paq.push(['setTrackerUrl', u+'matomo.php']);
    //     _paq.push(['setSiteId', '2']);
    //     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    //     g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    //   })();
    // </script>
    // <noscript><p><img src="https://analytics.laogw.la/matomo.php?idsite=2&amp;rec=1" style="border:0;" alt="" /></p></noscript>
    // <!-- End Matomo Code -->
  }

  click(form: any) {
    this.rest.CheckDuplicate(form).subscribe((res) => {
      this.popupCheckData = res.data;
      console.log("Mock Data:", this.popupCheckData);
    });
  }

  openDialog(form: any) {
    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
        data: this.popupCheckData,
      },
    });
  }
}
