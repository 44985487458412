<div class="navbar-wrapper">
  <!-- <a href="/index"> -->
  <aside class="navbar-evisa-logo">
    <div class="e-logo" (click)="goIndex()"></div>
  </aside>
  <!-- </a> -->

  <!-- DESKTOP MENU -->
  <nav class="navbar navbar-expand-lg fixed-top" style="z-index: 900">
    <div class="container">
      <ul class="navbar-nav ml-auto animated fadeIn text-dark">
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/index" translate>Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" style="cursor: pointer" data-toggle="dropdown" translate>Apply</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" *ngIf="agentToken == false" routerLink="/apply" routerLinkActive="link-active"
              translate>Apply eVisa</a>
            <a class="dropdown-item" *ngIf="agentToken == true" routerLink="/application" routerLinkActive="link-active"
              translate>Apply eVisa</a>
            <a class="dropdown-item" routerLink="/checkstatus" translate routerLinkActive="link-active">Status
              Enquiry</a>
            <a class="dropdown-item" routerLink="/retrieveapplication" translate="retrieve_application_id"
              routerLinkActive="link-active">Retrieve Application ID
            </a>
            <!-- <a
              class="dropdown-item"
              routerLink="/application-enquiry"
              translate
              routerLinkActive="link-active"
              >Application Enquiry</a
            > -->
          </div>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/fee-calculator" translate>eVisa Fee</a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/info" translate>Information</a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/faq" translate>FAQ</a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/about" translate>About</a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/contact" translate>Contact</a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/cart">
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="shopping-cart"
              class="svg-inline--fa fa-shopping-cart fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512">
              <path fill="currentColor"
                d="M551.991 64H129.28l-8.329-44.423C118.822 8.226 108.911 0 97.362 0H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h78.72l69.927 372.946C150.305 416.314 144 431.42 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-17.993-7.435-34.24-19.388-45.868C506.022 391.891 496.76 384 485.328 384H189.28l-12-64h331.381c11.368 0 21.177-7.976 23.496-19.105l43.331-208C578.592 77.991 567.215 64 551.991 64zM240 448c0 17.645-14.355 32-32 32s-32-14.355-32-32 14.355-32 32-32 32 14.355 32 32zm224 32c-17.645 0-32-14.355-32-32s14.355-32 32-32 32 14.355 32 32-14.355 32-32 32zm38.156-192H171.28l-36-192h406.876l-40 192z">
              </path>
            </svg>
            <span translate>Cart</span>
            <span *ngIf="countInCart.countCart > 0" class="badge-cart rounded-circle font-weight-normal animated shake"
              style="background-color: #ff3a1e; color: #fff">
              {{ countInCart.countCart }}
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="showFoundApp">
          <a class="nav-link" routerLink="/found-app">
            <img src="/assets/images/form.png" style="width: 25px; height: 25px;" alt="">
            <span translate>My Application</span>

          </a>
        </li>
        <li class="nav-item">
          <div class="btn-group" role="group">
            <button class="btn-en" [ngClass]="{ 'lang-btn-active': app.SelectedLang == 'en' }"
              (click)="switchLanguage('en')">
              EN
            </button>
            <button class="btn-cn" [ngClass]="{ 'lang-btn-active': app.SelectedLang == 'cn' }"
              (click)="switchLanguage('cn')">
              中文
            </button>
          </div>
        </li>
        <li class="nav-item" *ngIf="agentToken == true">
          <a class="nav-link" style="cursor: pointer" (click)="logout()">
            <i class="fa fa-sign-out"></i>
            Logout</a>
        </li>
      </ul>
      <div class="navbar-side-logos ml-auto ml-xl-0">
        <div class="navbar-side-logo navbar-side-logo-1"></div>
        <button type="button" class="navbar-menu-toggle">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bars"
            class="svg-inline&#45;&#45;fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z">
            </path>
          </svg>
        </button>
      </div>
    </div>
  </nav>

  <!-- MOBILE MENU (OFF-CANVAS) -->
  <nav class="navbar-menu-mobile-wrapper">
    <div class="text-right navbar-menu-mobile-toggle-wrapper">
      <button class="navbar-menu-toggle">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times"
          class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path fill="currentColor"
            d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z">
          </path>
        </svg>
      </button>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/index" translate>Home</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" style="cursor: pointer" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" translate>
          Apply
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" *ngIf="!agentToken" routerLink="/apply" translate>Apply eVisa</a>
          <a class="dropdown-item" *ngIf="agentToken" routerLink="/application" translate>Apply eVisa</a>
          <a class="dropdown-item" routerLink="/checkstatus" translate>Status Enquiry</a>
          <a class="dropdown-item" routerLink="/retrieveapplication" translate="retrieve_application_id">Retrieve
            Application ID</a>
        </div>
      </li>

      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/fee-calculator" translate>eVisa Fee</a>
      </li>
      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/info" translate>Information</a>
      </li>
      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/faq" translate>FAQ</a>
      </li>
      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/about" translate>About</a>
      </li>
      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/contact" translate>Contact</a>
      </li>
      <li class="nav-item" routerLinkActive="link-active">
        <a class="nav-link" routerLink="/cart">
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="shopping-cart"
            class="svg-inline--fa fa-shopping-cart fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512">
            <path fill="currentColor"
              d="M551.991 64H129.28l-8.329-44.423C118.822 8.226 108.911 0 97.362 0H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h78.72l69.927 372.946C150.305 416.314 144 431.42 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-17.993-7.435-34.24-19.388-45.868C506.022 391.891 496.76 384 485.328 384H189.28l-12-64h331.381c11.368 0 21.177-7.976 23.496-19.105l43.331-208C578.592 77.991 567.215 64 551.991 64zM240 448c0 17.645-14.355 32-32 32s-32-14.355-32-32 14.355-32 32-32 32 14.355 32 32zm224 32c-17.645 0-32-14.355-32-32s14.355-32 32-32 32 14.355 32 32-14.355 32-32 32zm38.156-192H171.28l-36-192h406.876l-40 192z">
            </path>
          </svg>
          <span><span translate>Cart</span>
            <span *ngIf="countInCart.countCart > 0" class="badge-cart rounded-circle font-weight-normal animated shake"
              style="background-color: #ff3a1e; color: #fff">
              {{ countInCart.countCart }}
            </span>
          </span>
        </a>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="globe"
            class="svg-inline--fa fa-globe fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
            <path fill="currentColor"
              d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z">
            </path>
          </svg>

          <span class="flag-icon flag-icon-us" *ngIf="NavSelectedLang == 'en'">ENGLISH</span>
          <span class="flag-icon flag-icon-cn" *ngIf="NavSelectedLang == 'cn'">中文</span>
          <span class="flag-icon flag-icon-fr" *ngIf="NavSelectedLang == 'fr'">FRENCH</span>
        </a>
        <div class="dropdown-menu" *ngIf="mLanguage">
          <div *ngFor="let item of mLanguage;">
            <a class="dropdown-item" *ngIf="MySelectedLang != item.language_name"
              (click)="switchLanguage(item.language_name)">
              <img src="assets/images/en_flag.png" />
              {{item.language_text}}
            </a>
          </div>
        </div>
      </li> -->
      <li class="nav-item">
        <div class="btn-group" role="group">
          <button (click)="switchLanguage('en')" class="btn-en" [ngClass]="{ 'lang-btn-active': button_lang === 'en' }">
            EN
          </button>
          <button (click)="switchLanguage('cn')" class="btn-cn" [ngClass]="{ 'lang-btn-active': button_lang === 'cn' }">
            中文
          </button>
        </div>
      </li>
      <li class="nav-item" *ngIf="agentToken == true">
        <a class="nav-link" style="cursor: pointer" (click)="logout()">
          <i class="fa fa-sign-out"></i>
          Logout</a>
      </li>
    </ul>
  </nav>
</div>