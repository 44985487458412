import { Component, OnInit, AfterViewInit } from "@angular/core";
import { RestService } from "src/app/services/rest.service";
import { CookiesService } from "src/app/services/cookies.service";
import Swal from "sweetalert2";
import offside from "offside-js";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { AppComponent } from "src/app/app.component";
import { MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { CheckArrivalComponent } from "../check-arrival/check-arrival.component";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit, AfterViewInit {
  maintenance: boolean;
  date_maintenance: string;

  safeHtmlContent: string;
  mContactSubject: any;
  mCountry: any;
  captchaImage: any;
  isImageLoading: boolean;

  isNotSuccess = false;
  isSuccess = false;

  message: string;
  // Model
  subject_id: number;
  firstName: string;
  title: string;
  surName: string;
  email: string;
  country_id: number;
  description: string;
  verify_code: string;

  constructor(
    private seo: ServiceSEOService,
    private rest: RestService,
    private cookie: CookiesService,
    public app: AppComponent,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.seo.addMetaTags();
  }

  ngOnInit() {
    this.getContactSubject();
    this.getCountry();
    this.getImageFromService();
    this.getMaintenanceService();
  }

  getMaintenanceService() {
    // this.rest.getMaintenance().subscribe(
    //   (data) => {
    //     this.maintenance = data.maintenance
    //     this.date_maintenance = data.date_maintenance
    //     console.log(data)
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  ngAfterViewInit(): void {
    // offside('.navbar-menu-mobile-wrapper', {
    //   buttonsSelector: '.navbar-menu-toggle',
    //   slidingSide: 'right'
    // });
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.captchaImage = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImageFromService() {
    this.isImageLoading = true;
    this.rest.getCaptchaImage().subscribe(
      (data) => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  getContactSubject() {
    this.rest.getContactSubject().subscribe(
      (result) => {
        this.mContactSubject = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getCountry() {
    this.rest.getCountrySubject().subscribe(
      (result) => {
        this.mCountry = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit(form: any) {
    if (form.valid === true) {
      this.spinner.show();
      this.verifyCaptcha(form);
    }
  }

  verifyCaptcha(form: any) {
    this.rest.verifyCaptchaContact(form.value).subscribe((result) => {
      if (result.responseStatus === false) {
        this.isNotSuccess = true;
        this.message = "Security code is wrong!";
        this.getImageFromService();
      } else {
        /*localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));*/
        this.spinner.hide();
        this.isSuccess = true;
        Swal.fire({
          type: "success",
          title: "Success!",
          html:
            // tslint:disable-next-line:max-line-length
            "<p>Thank you for contact us we will reply in shortly. </p>",
          showConfirmButton: false,
          cancelButtonColor: "#2F85D6",
          showCancelButton: true,
          cancelButtonText: "Close",
        });
      }
    });
  }

  reloadCaptcha() {
    this.getImageFromService();
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(CheckArrivalComponent);

  // }
}
